import React, { useRef, useState } from "react";

import {
    ScrollView,
    Text,
    View,
    Image,
    TouchableOpacity
} from "react-native";
import Images from "../../../../specific/utils/Images";
import Button from "../../../designSystem/Button";
import RadioButton from "../../../designSystem/RadioButton";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import SignUpStyle from "../../../../specific/style/Authentication/SignUpStyle";
import { createUserWithEmailAndPassword } from "../../../services/Auth";

import * as Constants from "../../../utils/Constants";
import Alert from "../../Notification/Alert";
import { addContactToGeneralAudience } from "../../../services/Mailing";
import { setUserProperty } from "../../../services/Tracking/Analytics";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import { validateEmail } from "../../../utils/StringUtils";

interface SignUpFormProps {
    type: "lah" | "las" | "laa",
    width?: number,
    onSuccess: (data: { email: string, password: string, firstname: string, lastname: string, optIn: boolean }) => void,
    openGeneralConditions: () => void
}

const emptyErrors = {
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    schoolName: "",
    schoolAddress: "",
    schoolZipCode: "",
    schoolCity: "",
    schoolCountry: ""
};

const SignUpForm = (props: SignUpFormProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const style: SignUpStyle = new SignUpStyle();

    const { type, width = appTheme.getBlockWidth() } = props;

    const [optInNewsletter, setOptInNewsLetter] = useState<boolean>(false);
    const [optInCoachNewsLetter, setOptInCoachNewsLetter] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState(undefined);

    const alertRef = useRef<Alert>(null);

    const openGeneralConditions = () => {
        props.openGeneralConditions();
    }

    const onSignUpRequest = () => {
        setIsLoading(true);
        let canGoOn: boolean = false;
        let errorType: string = "";
        if ((emailAddress.length > 0) && (password.length > 0)) {
            const isEmailValid = validateEmail(emailAddress);
            if (isEmailValid === true) {
                if (type === "lah") {
                    canGoOn = true;
                } else if ((firstname.length > 0) && (lastname.length > 0)) {
                    canGoOn = true;
                } else {
                    errorType = "missing";
                }
            } else {
                errorType = "email_format";
            }
        } else {
            errorType = "missing";
        }
        if (canGoOn === true) {
            const emailToRecord = emailAddress.toLowerCase().trim();
            if ((type === "las") || (type === "laa")) {
                props.onSuccess({ email: emailToRecord, password, firstname, lastname, optIn: optInNewsletter });
            } else {
                createUserWithEmailAndPassword({
                    context: Constants.CONTEXT.family,
                    email: emailToRecord,
                    password: password,
                    options: {
                        optInNewsletter,
                        optInCoachNewsLetter
                    }
                }).then(() => {
                    registerUserToMailing();
                }).catch((error) => {
                    setIsLoading(false);
                    let title = "Une erreur est survenue";
                    let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
                    if (error.toString().indexOf("auth/email-already-in-use") !== -1) {
                        title = "Utilisateur existant";
                        body = "Votre adresse e-mail est déjà reliée à un compte de LILI.\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\" dans la page \"J'AI DÉJÀ UN COMPTE\"";
                    } else if (error.toString().indexOf("auth/invalid-email") !== -1) {
                        title = "Adresse e-mail erronée";
                        body = "Veuillez vérifier votre adresse e-mail s'il vous plaît.";
                    } else if (error.toString().indexOf("auth/weak-password") !== -1) {
                        title = "Mot de passe trop faible";
                        body = "Veuillez saisir au moins 6 caractères pour votre mot de passe.";
                    } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                        title = "Erreur de connexion 📡";
                        body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
                    }
                    alertRef.current?.showAlert({
                        title,
                        body
                    });
                });
            }
        } else {
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: "Erreur sur votre saisie",
                body: errorType === "missing" ? "Les champs du formulaire ne peuvent pas être vides." : "Vérifiez le format de votre adresse e-mail"
            });
        }
    }

    const registerUserToMailing = async () => {
        const emailToRecord = emailAddress.toLowerCase().trim();
        try {
            await addContactToGeneralAudience({ email: emailToRecord, optIn: optInNewsletter, optInCoach: optInCoachNewsLetter });
            if (optInNewsletter === true) {
                registerUserToMailing();
            }
            setIsLoading(false);
            props.onSuccess({ email: emailToRecord, password, firstname, lastname, optIn: optInNewsletter });
        } catch (error) {
            setIsLoading(false);
            props.onSuccess({ email: emailToRecord, password, firstname, lastname, optIn: optInNewsletter });
        }
    }

    const getNameForm = () => {
        if ((type === "las") || (type === "laa")) {
            return <View style={{ flex: 1, alignItems: "center" }}>
                <TextInput
                    key="firstname"
                    value={firstname}
                    onFocus={() => setErrors(undefined)}
                    error={((errors !== undefined) && (errors.firstname !== undefined)) ? errors.firstname : ""}
                    onChangeText={(text) => setFirstname(text)}
                    type="default"
                    autoCapitalize="words"
                    placeholder={i18n.t("authentication.signUp.form.firstnamePlaceholder")} />
                <TextInput
                    key="lastname"
                    value={lastname}
                    onFocus={() => setErrors(undefined)}
                    error={((errors !== undefined) && (errors.lastname !== undefined)) ? errors.lastname : ""}
                    onChangeText={(text) => setLastname(text)}
                    type="default"
                    autoCapitalize="words"
                    placeholder={i18n.t("authentication.signUp.form.lastnamePlaceholder")} />
            </View>;
        }
        return <View />;
    }

    const getCoachNewsLetterOption = () => {
        if (type === "lah") {
            return <RadioButton value={optInCoachNewsLetter} style={{ width: radioButtonWidth }} radioButtonStyle={{ marginTop: appTheme.pixelPerfect(10) }} onPress={(optInCoachNewsLetter) => setOptInCoachNewsLetter(!optInCoachNewsLetter)}>
                <View style={style.coachFirstContainer}>
                    <Image source={images.icCoachManual} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(56) }} resizeMode="contain" />
                    <View style={{ flex: 1 }}>
                        <Text style={style.coachFirstText}>
                            {i18n.t("authentication.signUp.form.radioButtons.coachManual.firstPart")}
                            <Text style={style.radioButtonDefaultText}>
                                {i18n.t("authentication.signUp.form.radioButtons.coachManual.secondPart")}
                            </Text>
                        </Text>

                    </View>
                </View>
            </RadioButton>
        }
        return <View />
    }

    const buttonColor = type === "las" ? appTheme.schoolColor : type === "laa" ? appTheme.schoolColor : appTheme.darkBlue;

    const textInputWidth: number = type === "las" ? Math.ceil(width * 0.45) : Math.ceil(width * 0.6);
    const buttonWidth: number = Math.ceil(width * 0.45);
    const radioButtonWidth: number = Math.ceil(width * 0.8);
    const radioButtonColor: string = type === "lah" ? appTheme.homeColor : appTheme.schoolColor;

    return (<View style={style.formContainer}>
        <View style={{ width }}>
            {getNameForm()}
            <View style={{ flex: 1, alignItems: "center" }}>
                <TextInput
                    key="email"
                    value={emailAddress}
                    onFocus={() => setErrors(undefined)}
                    error={((errors !== undefined) && (errors.email !== undefined)) ? errors.email : ""}
                    onChangeText={(text) => setEmailAddress(text)}
                    type="email"
                    placeholder={i18n.t("authentication.signUp.form.emailPlaceholder")} />
                <TextInput
                    key="password"
                    value={password}
                    onFocus={() => setErrors(undefined)}
                    error={((errors !== undefined) && (errors.password !== undefined)) ? errors.password : ""}
                    onChangeText={(text) => setPassword(text)}
                    type="password"
                    placeholder={i18n.t("authentication.signUp.form.passwordPlaceholder")} />
            </View>
        </View>
        {getCoachNewsLetterOption()}
        <RadioButton
            buttonBackgroudnColor={radioButtonColor}
            centerButton={false}
            value={optInNewsletter}
            onPress={(optInNewsletter) => setOptInNewsLetter(!optInNewsletter)}
            style={{ marginVertical: appTheme.pixelPerfect(20), width: radioButtonWidth, backgroundColor: radioButtonColor + "22", borderRadius: appTheme.pixelPerfect(10), padding: appTheme.pixelPerfect(10) }}>
            <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), color: appTheme.darkBlue }}>
                {i18n.t("authentication.signUp.form.radioButtons.newsletter")}
            </Text>
        </RadioButton>
        <Button
            isLoading={isLoading}
            onPress={onSignUpRequest}
            title={i18n.t("authentication.signUp.buttons.signUp")}
            style={{ width: buttonWidth, marginTop: appTheme.pixelPerfect(10), backgroundColor: buttonColor }} />
        <TouchableOpacity onPress={openGeneralConditions} style={{ marginTop: appTheme.pixelPerfect(10) }}>
            <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center", color: appTheme.textColor }}>
                {i18n.t("authentication.signUp.buttons.cgu.firstPart")}
            </Text>
            <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center", textDecorationLine: "underline", color: appTheme.textColor }}>
                {i18n.t("authentication.signUp.buttons.cgu.secondPart")}
            </Text>
        </TouchableOpacity>
        <Alert ref={alertRef} />
    </View>)

}

export default SignUpForm;