import React, { useEffect, useRef, useState } from "react";

import { View, Image, Text, Platform, TouchableOpacity, Animated, ScrollView, Modal } from "react-native";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import AppTheme from "../../../utils/Theme";
import { useNavigate, useParams } from "react-router-dom";
import Activity from "../../../models/Activity";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import ActivityMediaListStyle from "../../../styles/activities/ActivityMediaListStyle";
import { pop } from "../../../../specific/utils/Navigation/Navigation";
import SVGView from "../../../utils/SvgView";
import { default as IcBack } from "../../../assets/svg/lili/ic_back_arrow.svg";
import { default as IcFilm } from "../../../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../../../assets/svg/lili/ic_audio_book.svg";
import { default as IcDocument } from "../../../assets/svg/lili/ic_pdf.svg";
import Style from "../../../utils/Style";
import i18n, { getLanguage } from "../../../utils/Localization/Localization";
import AudioPlayer from "../../../../specific/utils/AudioPlayer/AudioPlayer";
import ActivityPlayer from "../../Audio/ActivityPlayer";
import PlayButton from "../../../designSystem/PlayButton";
import { addDataToUser } from "../../../services/Database";
import User from "../../../data/user/User";
import ActivityCellTitle from "../ActivityCells/ActivityCellTitle";
import ActivityCellBody from "../ActivityCells/ActivityCellBody";
import Images from "../../../../specific/utils/Images";
import LiliVideoPlayer from "../../../../specific/components/VideoPlayer/LiliVideoPlayer";
import { getUrlToUse } from "../../../utils/LocalStorage";
import { getAudioUrlForMediaId, getVideoUrlForMediaId } from "../../../../specific/services/AppWrite/AppWriteDatabase";
import { getAppTarget } from "../../../../specific/utils/Navigation/Host";
import { propertiesEventContent, setUserProperty, trackEvent } from "../../../services/Tracking/Analytics";
import Divider from "../../../designSystem/Divider/Divider";

interface ActivityMediaSheetProps extends ComponentProps {
    category: any,
    activity: Activity,
    media?: any,
    webCloseRequest?: () => void,
    onMediaCompleted?: () => void
}

const ActivityMediaSheet = (props: ActivityMediaSheetProps) => {

    const appTheme: AppTheme = new AppTheme();
    const activityMediaListStyle: ActivityMediaListStyle = new ActivityMediaListStyle();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [launchAudioPlayer, setLaunchAudioPlayer] = useState<boolean>(false);
    const [launchVideoPlayer, setLaunchVideoPlayer] = useState<boolean>(false);
    const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
    const viewOpacity: Animated.Value = useRef(new Animated.Value(0)).current;

    const isPublicApp = getAppTarget();

    let propsData = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { category, activity, componentId, webCloseRequest, onMediaCompleted } = propsData;

    const onViewDidAppear = async () => {
        if (props.media !== undefined) {
            await checkUrl();
        }
    }

    useEffect(() => {
        if (props.media !== undefined) {
            checkUrl();
        }
    }, [props.media]);

    useEffect(() => {
        if (videoUrl !== undefined) {
            setViewDidAppear(true);
        }
    }, [videoUrl]);

    const checkUrl = async () => {
        const {type, id } = props.media;
        let aVideoUrl: string = "";
        if (type === "video") {
            aVideoUrl = getVideoUrlForMediaId({ media_id: id });
        } else if (type === "multi_formats") {
            // On va chercher le format audio
            for (const aMediaIndex in props.media.media) {
                if (Object.prototype.hasOwnProperty.call(props.media.media, aMediaIndex)) {
                    const aChoice = props.media.media[aMediaIndex];
                    if (aChoice.type === "video") {
                        aVideoUrl = getVideoUrlForMediaId({ media_id: id });
                        break;
                    }
                }
            }
        }
        aVideoUrl = await getUrlToUse({ url: aVideoUrl, activityKey: activity.key });
        setVideoUrl(aVideoUrl);
    }

    useEffect(() => {
        if (viewDidAppear === true) {
            if (props.media !== undefined) {
                trackEvent("media_viewed", {
                    activity_name: activity?.analytics_key,
                    media_name: props.media.id
                });
            }

            Animated.timing(viewOpacity, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false
            }).start();
        }
    }, [viewDidAppear]);

    const goBack = () => {
        if (Platform.OS === "web") {
            Animated.timing(viewOpacity, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false
            }).start(() => {
                if (webCloseRequest !== undefined) {
                    webCloseRequest();
                }
            });
        } else {
            pop({ componentId, navigate });
        }
    }

    const getBackButton = () => {
        if (Platform.OS === "web") {
            return <View />;
        }
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const buttonSize = appTheme.pixelPerfect(30);
        const leftPosition = Platform.OS === "web" ? appTheme.pixelPerfect(20) : appTheme.pixelPerfect(10);
        const topPosition = Platform.OS === "web" ? appTheme.pixelPerfect(15) : appTheme.pixelPerfect(5) + appTheme.topInsets;
        let leftButtonContent = <SVGView Component={IcBack} color="#1C3656" size={appTheme.pixelPerfect(12)} />;
        return <View style={{ position: "absolute", top: topPosition, left: leftPosition }}>
            <TouchableOpacity onPress={goBack} style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: appTheme.white, justifyContent: "center", alignItems: "center" }]}>
                {leftButtonContent}
            </TouchableOpacity>
        </View>
    }

    const launchMedia = (mediaToLaunch: { type: "audio" | "video" | "pdf" | "quote" }) => {
        const { type } = mediaToLaunch;
        if (category.key === "phi_questions") {
            setUserProperty({ key: "activity_training_philo_launched", value: "true", once: true });
        } else if (category.key === "wellBeing") {
            setUserProperty({ key: "activity_wellness_launched", value: "true", once: true });
        }
        if ((type === "audio") || (type === "quote")) {
            if (launchAudioPlayer === false) {
                trackEvent("media_launched", {
                    [propertiesEventContent]: "audio",
                    activity_name: activity?.analytics_key,
                    media_name: props.media.id
                });
            }
            togglePlayer();
        } else if (type === "video") {
            trackEvent("media_launched", {
                [propertiesEventContent]: "video",
                activity_name: activity?.analytics_key,
                media_name: props.media.id
            });

            setLaunchVideoPlayer(true);
        }
    }

    const getAudioPlayer = () => {
        const { url, type } = props.media;
        const { clr } = category;
        if (((type === "audio") || (type === "quote")) && (url !== undefined) && (url[getLanguage()] !== undefined) && (url[getLanguage()].length > 0)) {
            return <View style={{ marginTop: appTheme.pixelPerfect(10) }}>
                <PlayButton disabled={url[getLanguage()].length === 0} onPress={() => launchMedia({ type })} backgroundColor={clr.nav} />
            </View>;
        } else if (type === "multi_formats") {
            let actionButton = [];
            const actionsByLine = 2;
            const buttonSize = appTheme.pixelPerfect(80);
            for (const aMediaIndex in props.media.media) {
                if (Object.prototype.hasOwnProperty.call(props.media.media, aMediaIndex)) {
                    const aChoice = props.media.media[aMediaIndex];
                    const svgIcon = aChoice.type === "audio" ? IcHeadphones : aChoice.type === "video" ? IcFilm : IcDocument;
                    const subTitle = ((aChoice.title !== undefined) && (aChoice.title[getLanguage()] !== undefined) && (aChoice.title[getLanguage()].length > 0)) ? <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8), color: clr.nav, width: buttonSize }}>{aChoice.title[getLanguage()].toLocaleUpperCase()}</Text> : <View />;
                    actionButton.push(<TouchableOpacity key={aChoice.url} onPress={() => launchMedia({ type: aChoice.type })} style={{ alignItems: "center", justifyContent: "center", backgroundColor: appTheme.white }}>
                        <View style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, alignItems: "center", justifyContent: "center", backgroundColor: clr.nav }]}>
                            <SVGView Component={svgIcon} size={40} color={clr.txt} />
                        </View>
                        <View style={{ alignItems: "center", justifyContent: "center", marginTop: appTheme.pixelPerfect(10) }}>
                            <Text numberOfLines={3} style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(8), color: clr.nav, width: buttonSize }}>{i18n.t("choices." + aChoice.type).toLocaleUpperCase()}</Text>
                            {subTitle}
                        </View>
                    </TouchableOpacity>);
                }
            }
            const audioPlayerWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
            return <View style={{ marginTop: appTheme.pixelPerfect(10), flexDirection: "row", width: audioPlayerWidth, justifyContent: "space-evenly" }}>
                {actionButton.reverse()}
            </View>;
        }
    }

    const getMediaContent = () => {
        const { description, body, type, author } = props.media;
        let quoteView = <View />;
        if ((type === "quote") && (body !== undefined) && (body[getLanguage()] !== undefined) && (body[getLanguage()].length > 0)) {
            quoteView = <View style={{ backgroundColor: "#FFFFFF", marginVertical: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() - appTheme.pixelPerfect(40), paddingVertical: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), paddingHorizontal: appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(30) }}>
                <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(18), color: appTheme.darkBlue, textTransform: "uppercase", textAlign: "center" }}>{body[getLanguage()]}</Text>
                <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, textAlign: "center" }}>{author}</Text>
            </View>
        }
        let bodyView = <View />;
        if ((type !== "quote") && (body !== undefined) && (body[getLanguage()] !== undefined) && (body[getLanguage()].length > 0)) {
            bodyView = <View style={{ marginVertical: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(30) }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(11), color: appTheme.darkBlue, paddingTop: appTheme.pixelPerfect(5), textAlign: "center" }}>{body[getLanguage()]}</Text>
            </View>
        }
        let descriptionView = [];
        if ((description !== undefined) && (description.length > 0)) {
            let textAlign = "center";
            for (const aDescriptionIndex in description) {
                if (Object.prototype.hasOwnProperty.call(description, aDescriptionIndex)) {
                    const aDescriptionItem = description[aDescriptionIndex];
                    let itemView = [];
                    if ((aDescriptionItem.title !== undefined) && (aDescriptionItem.title[getLanguage()] !== undefined)) {
                        textAlign = "left";
                        itemView.push(<Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(11), marginBottom: appTheme.pixelPerfect(5), marginTop: appTheme.pixelPerfect(15), textAlign }}>{aDescriptionItem.title[getLanguage()]}</Text>);
                    }
                    if ((aDescriptionItem.body !== undefined) && (aDescriptionItem.body[getLanguage()] !== undefined)) {
                        for (const aBodyIndex in aDescriptionItem.body[getLanguage()]) {
                            if (Object.prototype.hasOwnProperty.call(aDescriptionItem.body[getLanguage()], aBodyIndex)) {
                                const aBodyItem = aDescriptionItem.body[getLanguage()][aBodyIndex];
                                itemView.push(<Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(11), color: appTheme.darkBlue, paddingTop: appTheme.pixelPerfect(5), textAlign }}>{aBodyItem}</Text>)
                            }
                        }
                    }
                    descriptionView.push(itemView);
                }
            }
        }
        const { clr } = category;
        let title: string = activity.title[getLanguage()];
        if (activity[user.profile] !== undefined) {
            const userProfileItem = activity[user.profile];
            if ((userProfileItem.title !== undefined) && (userProfileItem.title[getLanguage()] !== undefined)) {
                title = userProfileItem.title[getLanguage()];
            }
        }
        if (Platform.OS === "web") {
            return <View>
                <View style={{ width: appTheme.getBlockWidth(), alignItems: "center" }}>
                    <Text style={{ textTransform: "uppercase", marginTop: appTheme.pixelPerfect(15), color: clr.nav, fontFamily: appTheme.primarySemiBoldFont, fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(8) : appTheme.pixelPerfectForFont(6) }}>{title}</Text>
                    <ActivityCellTitle title={props.media.title} duration={props.media.duration} tags={""} color={clr.nav} width={appTheme.getBlockWidth()} />
                    {getAudioPlayer()}
                </View>
                {bodyView}
                <View style={{ marginVertical: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(30) }}>
                    {descriptionView}
                </View>
            </View>

        };

        const titleColor = category.key === "quotes" ? "#FFFFFF" : clr.nav;
        const viewTitle = category.key === "quotes" ? <Divider /> : <Text style={{ textTransform: "uppercase", marginTop: appTheme.pixelPerfect(15), color: clr.nav, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(6) }}>{title}</Text>;
        const scrollViewStyle = category.key === "quotes" ? [activityMediaListStyle.sheetContentScrollView, { height: appTheme.getFullScreenHeight() }] : activityMediaListStyle.sheetContentScrollView;
        return <ScrollView style={scrollViewStyle}>
            <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", flex: 1 }}>
                {viewTitle}
                <ActivityCellTitle title={props.media.title} duration={props.media.duration} tags={""} color={titleColor} width={appTheme.getFullAppWidth()} />
                {quoteView}
                {getAudioPlayer()}
            </View>
            {bodyView}
            <View style={{ marginTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(20) }}>
                {descriptionView}
            </View>
        </ScrollView>
    }

    const onMediaAudioCompleted = (mediaType: string) => {
        trackEvent("media_completed", {
            [propertiesEventContent]: mediaType,
            activity_name: activity?.analytics_key,
            media_name: props.media.id
        });
        if ((props.media.locked !== undefined) && (props.media.locked === true)) {
            const user: User = User.getInstance();
            let userMedia = {};
            if (user.media !== undefined) {
                userMedia = user.media;
            }
            let userMediaForCategory = [];
            if (userMedia[category.key] !== undefined) {
                userMediaForCategory = userMedia[category.key];
            }
            if (userMediaForCategory.indexOf(props.media.id) === -1) {
                userMediaForCategory.push(props.media.id);
                userMedia[category.key] = userMediaForCategory;
                user.media = userMedia;
                addDataToUser({ key: "media", value: userMedia, user_id: user.uid });
                if (onMediaCompleted !== undefined) {
                    onMediaCompleted();
                }
            }
        }
    }

    const togglePlayer = () => {
        // A remettre pour le tracking
        setLaunchAudioPlayer(!launchAudioPlayer);
    }

    const getModalContent = () => {
        const { title, url, type, id } = props.media;
        if (launchAudioPlayer === true) {
            let img_url: string = activity.img_url;
            let app_image: string = activity.app_image;
            const { clr } = category;
            // On va regarder si on a une iimage spécifique pour le type d'utilisateur
            if (activity[user.profile] !== undefined) {
                const userProfileItem = activity[user.profile];
                if (userProfileItem.app_image !== undefined) {
                    app_image = userProfileItem.app_image;
                }
                if (userProfileItem.img_url !== undefined) {
                    img_url = userProfileItem.img_url;
                }
            }
            const imagePath = images[app_image] !== undefined ? app_image : img_url;
            const imageSource = images[app_image] !== undefined ? images[app_image] : { uri: img_url };
            let urls: string[] = [];
            if ((type === "audio") || (type === "quote")) {
                urls = [url[getLanguage()]];
                if (isPublicApp !== "public") {
                    urls = [getAudioUrlForMediaId({ media_id: id })]
                }
            } else if (type === "multi_formats") {
                // On va chercher le format audio
                for (const aMediaIndex in props.media.media) {
                    if (Object.prototype.hasOwnProperty.call(props.media.media, aMediaIndex)) {
                        const aChoice = props.media.media[aMediaIndex];
                        if (aChoice.type === "audio") {
                            urls = [aChoice.url[getLanguage()]];
                            if (isPublicApp !== "public") {
                                urls = [getAudioUrlForMediaId({ media_id: id })]
                            }
                            break;
                        }
                    }
                }
            }
            
            if (isPublicApp === "public") {
                if (category.key === "phi_questions") {
                    // On va ajouter le jingle je grandis avant et après les urls
                    urls.unshift(require("../../../assets/audio/lili_je_grandis.mp3"));
                    urls.push(require("../../../assets/audio/lili_je_grandis.mp3"));
                } else if (category.key === "wellBeing") {
                    urls.unshift(require("../../../assets/audio/lili_je_me_pose.mp3"));
                    urls.push(require("../../../assets/audio/lili_je_me_pose.mp3"));
                }
            }
            
            return <ActivityPlayer
            activityKey={activity.key}
                title={title[getLanguage()]}
                notificationImage={Platform.OS === "ios" ? img_url : imagePath}
                onActivityCompleted={() => onMediaAudioCompleted("audio")}
                urls={urls}
                color={clr.header_bg}
                localImage={imageSource}
                remoteImage={activity.img_url}
                togglePlayer={togglePlayer} />
        } else if (launchVideoPlayer === true) {
            return <LiliVideoPlayer
                onVideoCompleted={() => onMediaAudioCompleted("video")}
                url={videoUrl}
                closeTheDocument={() => setLaunchVideoPlayer(false)} />
        }
        return <View />;
    }

    const getContent = () => {
        if ((viewDidAppear === false) || (props.media === undefined)) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.darkBlue} />
            </View>
        }
        let img_url: string = activity.img_url;
        let app_image: string = activity.app_image;
        const { clr } = category;
        // On va regarder si on a une iimage spécifique pour le type d'utilisateur
        if (activity[user.profile] !== undefined) {
            const userProfileItem = activity[user.profile];
            if (userProfileItem.app_image !== undefined) {
                app_image = userProfileItem.app_image;
            }
            if (userProfileItem.img_url !== undefined) {
                img_url = userProfileItem.img_url;
            }
        }
        const backgroundColor: string = category.key === "quotes" ? appTheme.talk : appTheme.white;
        const imageSource = images[app_image] !== undefined ? images[app_image] : { url: img_url };
        const headerImg = ((Platform.OS === "web") || (category.key === "quotes")) ? <View /> : <Image source={imageSource} style={activityMediaListStyle.headerImage} resizeMode="cover" />;
        const contentWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        return <View style={{ height: "100%", backgroundColor, width: contentWidth, alignItems: "center", opacity: Platform.OS === "web" ? viewOpacity : 1 }}>
            <View style={[activityMediaListStyle.innerContainerStyle, { backgroundColor }]}>
                {headerImg}
                {getMediaContent()}
                {getBackButton()}
            </View>
            <Modal animationType="slide" visible={(launchAudioPlayer === true) || (launchVideoPlayer === true)} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", backgroundColor: clr.nav + "80", width: appTheme.getFullScreenWidth() }}>
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "flex-end", width: appTheme.getFullAppWidth() }}>
                        {getModalContent()}
                    </View>
                </View>
            </Modal>
        </View>
    }

    return getContent();
}

export default ActivityMediaSheet;