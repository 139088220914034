import React, { useEffect, useState } from 'react';
import { Location, Outlet, useNavigate } from 'react-router-dom';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native';
import { currentUser } from '../../../shared/services/Auth';
import Splashscreen from '../../../shared/components/Splashcreen/Splashscreen';
import { AppTarget, getAppTarget } from './Host';
import CasSplashscreen from '../../../shared/components/Splashcreen/CasSplashscreen';
import User from '../../../shared/data/user/User';
import { EventRegister } from '../../../shared/utils/EventListeners';
import { EventUserAuthDB } from '../../../shared/utils/Constants';
import { setUserProperty } from '../../services/Tracking/Analytics';
import { getUserWithId } from '../../services/Specific_Database';
import { getUser, getUserWithGARIDO } from '../../services/AppWrite/AppWriteDatabase';
import DeepLink from "../../../shared/services/DeepLink/DeepLink";

interface ProtectedRouteProps {
  location: Location
}

export const ProtectedRoute = (props:ProtectedRouteProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUserConnected, setIsUserConnected] = useState<boolean | undefined>(undefined);

  const {location} = props;

  const user: User = User.getInstance();

  const onViewDidAppear = async () => {
    const authUser = await currentUser();
    if (authUser === null) {
      setIsUserConnected(false);
    } else {
      getUserData(authUser);
    }
  }

  const getUserData = async (userAuthData) => {
    await user.setAuthUserData(userAuthData);
    const appTarget: AppTarget = getAppTarget();
    const userData = appTarget === "public" ? await getUserWithId({ user_id: userAuthData?.uid }) : await getUserWithGARIDO(userAuthData?.ido);
    if (userData === null) {
      setIsUserConnected(false);
    } else {
      await user.setDBUserData(userData);
      EventRegister.emit(EventUserAuthDB, userData);
      setIsUserConnected(true);
    }
  }

  useEffect(() => {
    if (isUserConnected !== undefined) {
      setIsLoading(false);
    }
  }, [isUserConnected]);

  const getContent = () => {
    if (isLoading === true) {
      return <View onLayout={onViewDidAppear} style={{width:"100%", height:"100%"}}>
        <ActivityIndicator color={"0000FF"} />
      </View>
    }

    const deepLink: DeepLink = DeepLink.getInstance();
    const connextionStatus: boolean = ((isUserConnected === true) || ((user.uid !== undefined) && (user.uid.length > 0)));
    if ((connextionStatus === false) || (deepLink.last_urls.length === 0)) {
      deepLink.path = location.pathname;
      const appTarget: AppTarget = getAppTarget();
      if (appTarget === "public") {
        return <Splashscreen />;
      } else {
        return <CasSplashscreen />;
      }
    }
    return <Outlet />;
  }

  return getContent();
};
