import React, { useEffect, useRef, useState } from "react";

import {
    Animated,
    Image,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import AppTheme from "../../../utils/Theme";
import DynamicBackground from "../../DynamicBackground/DynamicBackground";
import AuthHeader from "./AuthHeader";
import AuthTabButton from "../../../designSystem/Buttons/AuthTabButton";
import LandingStyle from "../../../styles/auth/LandingStyle";
import i18n from "../../../utils/Localization/Localization";
import Images from "../../../../specific/utils/Images";
import Divider from "../../../designSystem/Divider/Divider";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import AuthFooter from "./AuthFooter";
import ForgotPassword from "./ForgotPassword";
import TeacherSignUp from "./TeacherSignUp";
import KeyboardAwareScrollView from "../../../../specific/views/KeyboardAwareScrollView";
import ParentSignUp from "./ParentSignUp";
import AnimatorSignUp from "./AnimatorSignUp";
import ActionSheet from "../../ActionSheets/ActionSheet";

interface LandingProps extends ComponentProps {

}

const Landing = (props: LandingProps) => {

    const appTheme: AppTheme = new AppTheme();
    const landingStyle: LandingStyle = new LandingStyle();
    const images: Images = new Images();

    const signInPagerRef = useRef<ScrollView>(null);
    const mainViewPagerRef = useRef<ScrollView>(null);

    const [selectedUserType, setSelectedUserType] = useState<UserType>("neutral");
    const [userAlreadySignedIn, setUserAlreadySignedIn] = useState<boolean>(false);
    const [signUpConfirmed, setSignUpConfirmed] = useState<boolean>(false);

    const leftPosition = useRef(new Animated.Value(0)).current;
    const leftBorderRadius = useRef(new Animated.Value(0)).current;
    const rightBorderRadius = useRef(new Animated.Value(15)).current;
    const signUpViewOpacity = useRef(new Animated.Value(1)).current;
    const signInViewOpacity = useRef(new Animated.Value(0)).current;
    const mainViewMarginTop = useRef(new Animated.Value(0)).current;

    const [userEmail, setUserEmail] = useState<string>("");

    const actionSheetRef = useRef<ActionSheet>(null);

    useEffect(() => {
        const newLeftPositionValue = userAlreadySignedIn === false ? 0 : appTheme.getBlockWidth() / 2;
        const newLeftBorderRadiusPosition = userAlreadySignedIn === false ? 0 : appTheme.pixelPerfect(15);
        const newRightBorderRadiusPosition = userAlreadySignedIn === false ? appTheme.pixelPerfect(15) : 0;
        const newSignUpViewOpacity = userAlreadySignedIn === false ? 1 : 0;
        const newSignInViewOpacity = userAlreadySignedIn === false ? 0 : 1;
        Animated.parallel([
            Animated.timing(leftPosition, {
                toValue: newLeftPositionValue,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(leftBorderRadius, {
                toValue: newLeftBorderRadiusPosition,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(rightBorderRadius, {
                toValue: newRightBorderRadiusPosition,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(signUpViewOpacity, {
                toValue: newSignUpViewOpacity,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(signInViewOpacity, {
                toValue: newSignInViewOpacity,
                duration: 500,
                useNativeDriver: false
            })
        ]).start();
    }, [userAlreadySignedIn]);

    useEffect(() => {
        if (signUpConfirmed === true) {
            Animated.parallel([
                Animated.timing(leftBorderRadius, {
                    toValue: appTheme.pixelPerfect(15),
                    duration: 500,
                    useNativeDriver: false
                }),
                Animated.timing(rightBorderRadius, {
                    toValue: appTheme.pixelPerfect(15),
                    duration: 500,
                    useNativeDriver: false
                }),
                Animated.timing(mainViewMarginTop, {
                    toValue: appTheme.pixelPerfect(20),
                    duration: 500,
                    useNativeDriver: false
                })
            ]).start();
        }
    }, [signUpConfirmed])

    useEffect(() => {
        if (mainViewPagerRef !== undefined) {
            mainViewPagerRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        }
    }, [selectedUserType]);

    const switchType = (targetType: UserType) => {
        setSelectedUserType(targetType);
    }

    const showPageWithNumber = (pageNumber: number) => {
        signInPagerRef.current?.scrollTo({ x: pageNumber * appTheme.getBlockWidth(), y: 0, animated: true });
    }

    const forgotPasswordCompleted = (email: string) => {
        setUserEmail(email);
        showPageWithNumber(0);
    }

    const onSignUpConfirmed = () => {
        setSignUpConfirmed(true);
    }

    const getMainViewByAuthType = () => {
        if (userAlreadySignedIn === false) {
            let signUpPage: React.JSX.Element = <SignUp componentId={props.componentId} onButtonsPressed={switchType} />;
            if (selectedUserType === "teacher") {
                signUpPage = <TeacherSignUp userEmail={userEmail} componentId={props.componentId} onSignUpConfirmed={onSignUpConfirmed} onCancelRequest={() => setSelectedUserType("neutral")} />;
            } else if (selectedUserType === "family") {
                signUpPage = <ParentSignUp userEmail={userEmail} componentId={props.componentId} onSignUpConfirmed={onSignUpConfirmed} onCancelRequest={() => setSelectedUserType("neutral")} />;
            } else if (selectedUserType === "animator") {
                signUpPage = <AnimatorSignUp componentId={props.componentId}  onSignUpConfirmed={onSignUpConfirmed}  onCancelRequest={() => setSelectedUserType("neutral")} />
            }
            return <Animated.View style={{ alignItems: "center", opacity: signUpViewOpacity, minHeight: appTheme.pixelPerfect(240), justifyContent: "center" }}>
                {signUpPage}
            </Animated.View>;
        }

        return <Animated.View style={{ backgroundColor:"#FFF", alignItems: "center", opacity: signInViewOpacity, minHeight: appTheme.pixelPerfect(240), justifyContent: "center" }}>
            <ScrollView scrollEnabled={false} ref={signInPagerRef} horizontal={true} style={landingStyle.contentContainer}>
                <SignIn userEmail={userEmail} componentId={props.componentId} onChangePasswordRequest={() => showPageWithNumber(1)} />
                <ForgotPassword componentId={props.componentId} onGoBackToSignInRequest={forgotPasswordCompleted} />
            </ScrollView>
        </Animated.View>;
    }

    const openCodeSheet = () => {
        actionSheetRef.current.showAlert({
            actionSheetType: "CODE",
            data: {
            }
        });
    }

    const getCodeButton = () => {
        return <View />
        return <View style={{ position: "absolute", alignItems: "center" }}>
            <View style={landingStyle.tabsView}>
                <TouchableOpacity onPress={openCodeSheet} style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#FFFFFF", position: "absolute", right: 0, top: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20) }}>
                    <Text style={landingStyle.tabButtonTitle}>J'ai un code</Text>
                </TouchableOpacity>
            </View>
        </View>
    }

    const getContent = () => {
        const maincContentStyle = Platform.OS === "web" ? { alignItems: "center", minHeight: appTheme.getFullAppHeight() } : { alignItems: "center" };
        const unselectedColorForTabTitle = selectedUserType === "family" ? appTheme.white : appTheme.darkBlue;
        const headerView = signUpConfirmed === false ? <AuthHeader type={selectedUserType} /> : <View />;
        const tabView = signUpConfirmed === false ? <View style={landingStyle.tabsView}>
            <Animated.View style={{ position: "absolute", height: appTheme.pixelPerfect(40), width: "50%", backgroundColor: appTheme.white, left: leftPosition, borderTopEndRadius: appTheme.pixelPerfect(15), borderTopStartRadius: appTheme.pixelPerfect(15) }} />
            <AuthTabButton unselectedColor={unselectedColorForTabTitle} isSelected={!userAlreadySignedIn} onPress={() => setUserAlreadySignedIn(false)} title={i18n.t("authentication.landing.signup")} />
            <AuthTabButton unselectedColor={unselectedColorForTabTitle} isSelected={userAlreadySignedIn} onPress={() => setUserAlreadySignedIn(true)} title={i18n.t("authentication.landing.signin")} />
        </View> : <View />;
        const mainContent = <View style={maincContentStyle}>
            {headerView}
            <Animated.View style={{ marginTop: mainViewMarginTop }}>
                {tabView}
                <Animated.View style={[landingStyle.contentContainer, { borderTopRightRadius: rightBorderRadius, borderTopLeftRadius: leftBorderRadius, paddingVertical: appTheme.pixelPerfect(20) }]}>
                    {getMainViewByAuthType()}
                </Animated.View>
            </Animated.View>
            <AuthFooter type={selectedUserType} />
            <Divider size={30} />
            {getCodeButton()}
        </View>;
        return mainContent;
    }

    return <View style={{ flex: 1, justifyContent: "flex-start", alignItems: "center", backgroundColor: appTheme.white, paddingTop: appTheme.topInsets }}>
        <DynamicBackground type={selectedUserType} />
        <KeyboardAwareScrollView ref={mainViewPagerRef} showsVerticalScrollIndicator={false} style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
            {getContent()}
        </KeyboardAwareScrollView>
        <ActionSheet componentId={props.componentId} ref={actionSheetRef} onChange={() => {}} />
    </View>

}

export default Landing;