import React, { useState } from "react";

import {
    View,
    Text,
    Image,
    TouchableOpacity
} from "react-native";

import AppTheme from "../../../utils/Theme";
import Divider from "../../../designSystem/Divider/Divider";
import Images from "../../../../specific/utils/Images";
import Button from "../../../designSystem/Button";
import SVGView from "../../../utils/SvgView";
import { default as IcCheck } from "../../../assets/svg/icons/solid/check.svg";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import i18n from "../../../utils/Localization/Localization";

export type TeacherFunction = {
    id: string,
    label: string
}

interface SelectTeacherFunctionProps {
    onSelectionDone?:(insitutionType:InstitutionType) => void
}

const SelectTeacherFunction = (props: SelectTeacherFunctionProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [selectedInstitutionType, setSelectedInstitutionType] = useState<InstitutionType|undefined>(undefined);
    const [otherInstitutionName, setOtherInstitutionName] = useState<string>("");

    const teacherFunctions: TeacherFunction[] = [
        {label: i18n.t("authentication.signUp.form.teachers.functions.teacher"), id:"teacher"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.documentalist"), id:"documentalist"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.aesh"), id:"aesh"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.atsem"), id:"atsem"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.establishment_head"), id:"establishment_head"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.medico_social"), id:"medico_social"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.inspector"), id:"inspector"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.director_executive"), id:"director_executive"}, 
        {label: i18n.t("authentication.signUp.form.teachers.functions.other"), id:"other"}
    ];
    const choiceCell = (item: InstitutionType, aChoiceIndex:string) => {
        const checkStatus = ((selectedInstitutionType !== undefined) && (item.id === selectedInstitutionType.id)) ? <SVGView Component={IcCheck} color={appTheme.darkBlue+"DD"} size={appTheme.pixelPerfect(14)} /> : <View />;
        const borderLineWidth:number = parseInt(aChoiceIndex) === 0 ? 0 : 1;
        return <TouchableOpacity onPress={() => setSelectedInstitutionType(item)} key={item.id} style={{ flexDirection:"row", width:"100%", height:appTheme.pixelPerfect(40), borderTopWidth:borderLineWidth, borderColor:appTheme.darkBlue+"40", alignItems:"center"}}>
             <Text style={{ flex:1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8), textTransform: "uppercase", color: appTheme.darkBlue }}>
                {item.label}
            </Text>
            {checkStatus}
        </TouchableOpacity>
    }

    const getOtherTextInput = () => {
        if (selectedInstitutionType?.id === "other") {
            return <TextInput onChangeText={(text) => setOtherInstitutionName(text)} placeholder="Veuillez précisez s'il vous plait" />
        }
        return <View />
    }

    const getchoices = () => {
        let choicesView = [];
        for (const aChoiceIndex in teacherFunctions) {
            if (Object.prototype.hasOwnProperty.call(teacherFunctions, aChoiceIndex)) {
                const aTeacherFunction:TeacherFunction = teacherFunctions[aChoiceIndex];
                choicesView.push(choiceCell(aTeacherFunction, aChoiceIndex));
            }
        }
        return choicesView;
    }

    const sendTheSelection = () => {
        if (props.onSelectionDone !== undefined) {
            if (selectedInstitutionType !== undefined) {
                if (selectedInstitutionType.id === "other") {
                    props.onSelectionDone({id:"other", label:otherInstitutionName});
                } else {
                    props.onSelectionDone(selectedInstitutionType);
                }
            }
            
        }
    }

    const getSelectionButton = () => {
        if (selectedInstitutionType === undefined) {
            return <View />
        }
        if ((selectedInstitutionType.id === "other") && (otherInstitutionName.length === 0)) {
            return <View />
        }
        return <RoundButton onPress={sendTheSelection} title={"Valider mon choix"}/>
    }

    const getContent = () => {
        return <View style={{ width: "100%", backgroundColor: "#FFF", alignItems: "center" }}>
            <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(9), textTransform: "uppercase", color: appTheme.darkBlue }}>
                {"Quelle est votre fonction ?"}
            </Text>
            <Divider />
            {getchoices()}
            {getOtherTextInput()}
            <Divider />
            {getSelectionButton()}
        </View>
    }

    return <View>
        {getContent()}
    </View>
}

export default SelectTeacherFunction;