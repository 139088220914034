import React, { useEffect, useState, useRef } from "react";

import {
    Text,
    View,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    StyleSheet,
    Image
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";

import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Label from "../../utils/Localization/Label";

import i18n from "../../utils/Localization/Localization";

import SVGView from "../../utils/SvgView";
import { default as IcGift } from "../../assets/svg/icons/solid/gift.svg";
import { default as IcUsers } from "../../assets/svg/icons/solid/users.svg";
import { default as IcBug } from "../../assets/svg/icons/solid/bug.svg";
import { default as IcEdit } from "../../assets/svg/icons/solid/edit.svg";
import { default as IcChartLine } from "../../assets/svg/icons/solid/chart-line.svg";
import { default as IcQuoteRight } from "../../assets/svg/icons/solid/quote-right.svg";
import { default as IcOffline } from "../../assets/svg/icons/solid/ic_offline.svg";

// DATA
import * as Constants from "../../utils/Constants";

// Services
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import { EventRegister } from "../../utils/EventListeners";


import User from "../../data/user/User";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "../../../specific/utils/LocalStorage";
import { addDataToUser } from "../../services/Database";

import Alert from "../Notification/Alert";

const usersCell = {
    id: 'users',
    title: i18n.t('superAdmin.users'),
    icon: IcUsers,
    color: "#FF0066"
};

const giftsCell = {
    id: 'gifts',
    title: i18n.t('superAdmin.gifts'),
    icon: IcGift,
    color: "#FFB000"
};

const citationsCell = {
    id: 'quotes',
    title: i18n.t('superAdmin.quotes'),
    icon: IcQuoteRight,
    color: "#FF0066"
};

const bugsCell = {
    id: 'bug',
    title: i18n.t('superAdmin.bugs'),
    icon: IcBug,
    color: "#FF6600"
};

const teacherProfileModeCell = {
    id: 'teacherProfileMode',
    title: i18n.t('superAdmin.teacherProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const animatorProfileModeCell = {
    id: 'animatorProfileMode',
    title: i18n.t('superAdmin.animatorProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const familyProfileModeCell = {
    id: 'familyProfileMode',
    title: i18n.t('superAdmin.familyProfileMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const editionModeCell = {
    id: 'editionMode',
    title: i18n.t('superAdmin.editionMode'),
    icon: IcEdit,
    color: "#FFB000"
};

const offlineModeCell = {
    id: 'offlineMode',
    title: i18n.t('superAdmin.offlineMode'),
    icon: IcOffline,
    color: "#FFB000"
};

const tneStat = {
    id: 'tneStats',
    title: i18n.t('superAdmin.tneStat'),
    icon: IcChartLine,
    color: "#2196F3"
};

const SuperAdmin = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const userInstance: User = User.getInstance();
    const label: Label = new Label();


    const alertRef = useRef<Alert>(null);

    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [isTeacher, setIsTeacher] = useState(userInstance.isTeacher());
    const [user, setUser] = useState(userInstance.getUserData());
    const [editionModeValue, setEditionModeValue] = useState<boolean | undefined>(undefined);

    const headerWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() / 3 : appTheme.getBlockWidth();
    const listWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() - headerWidth : appTheme.getBlockWidth();

    let menuData = userInstance.admin === true ? [
        [
            giftsCell,
            citationsCell
        ],
        [
            editionModeCell
        ],
        [
            offlineModeCell
        ],
        [
            teacherProfileModeCell,
            animatorProfileModeCell,
            familyProfileModeCell,
        ]
    ] : userInstance.bizdev === true ? [[
        teacherProfileModeCell,
        animatorProfileModeCell,
        familyProfileModeCell,
    ]] : [];

    // MainOptions
    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }
    const mainOptions: MainOptions = new MainOptions({
        key: "super_admin",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        navigationColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    useEffect(() => {
        EventRegister.addEventListener(Constants.EventUserAuthDB, (data) => {
            const userData = userInstance.getUserData();
            setIsTeacher(userData.isTeacher());
        });
        return () => {
            EventRegister.removeEventListener(Constants.EventUserAuthDB);
        };
    }, []);

    const openItem = async (item: any, isUserSubscriber: boolean) => {
        if ((item.id === "gifts") || (item.id === "tneStats") || (item.id === "quotes") || (item.id === "offlineMode")) {
            let name = Constants.ScreenAdminGifts;
            if (item.id === "tneStats") {
                name = Constants.ScreenAdminTNEStats;
            } else if (item.id === "quotes") {
                name = Constants.ScreenAdminQuoteManagement;
            } else if (item.id === "offlineMode") {
                name = Constants.ScreenAdminOfflineManagement;
            }
            Navigation.push({
                componentId,
                navigate,
                name,
                passProps: {
                }
            });
        } else if (item.id === "editionMode") {
            try {
                const adminDataString = await getLocalStorage({ key: Constants.keyAdminData });
                let adminData = {};
                if (adminDataString !== null) {
                    adminData = JSON.parse(adminDataString);
                }
                adminData.editionMode = !editionModeValue;
                await setLocalStorage({ key: Constants.keyAdminData, value: JSON.stringify(adminData) });
                setEditionModeValue(!editionModeValue);
            } catch (error) {

            }
        } else if ((item.id === "teacherProfileMode") || (item.id === "animatorProfileMode") || (item.id === "familyProfileMode")) {
            try {
                const newProfile: Constants.UserType = item.id === "teacherProfileMode" ? "teacher" : item.id === "animatorProfileMode" ? "animator" : "family";
                alertRef.current?.showAlert({
                    title: "Changement de profil",
                    body: "Ton profil va être modifié, et l'app relancée !",
                    primaryButtonTitle: "Confirmer",
                    onPressPrimaryButton: () => changeProfile(newProfile),
                    secondaryButtonTitle: "Annuler"
                });
            } catch (error) {
            }
        } else if (item.id === "bug") {
        }
    }

    const changeProfile = async (newProfile: Constants.UserType) => {
        try {
            await addDataToUser({ key: "profile", value: newProfile, user_id: user.uid });
            Navigation.relaunchApp({ componentId, navigate });
        } catch (error) {

        }
    }

    const keyExtractor = (item, index) => {
        return (index.toString());
    }

    const aboutCell = ({ item, index }) => {

        const fullWidthForCell = listWidth - appTheme.pixelPerfect(20);

        let cell = [];
        for (const aCellIndex in item) {
            if (Object.prototype.hasOwnProperty.call(item, aCellIndex)) {
                const aCell = item[parseInt(aCellIndex)];
                const cellWidth = item.length === 1 ? fullWidthForCell : fullWidthForCell / 2 - appTheme.pixelPerfect(5);
                if (aCell.id === "empty") {
                    cell.push(<View style={{ width: cellWidth, marginBottom: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }} />);
                } else {
                    let cellHeight = item.length === 1 ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(100);
                    let horizontalAlign = item.length === 1 ? "flex-start" : "flex-end";
                    let iconStyle = { flex: 1, justifyContent: "center" };
                    let iconColor = aCell.color;
                    if (item.length === 1) {
                        iconStyle = { width: appTheme.pixelPerfect(40), justifyContent: "center" };
                    }
                    const cellFlexDirection = item.length === 1 ? "row" : "column";
                    const cellPaddingTop = item.length === 1 ? appTheme.pixelPerfect(5) : appTheme.pixelPerfect(10);
                    const cellPaddingBottom = item.length === 1 ? appTheme.pixelPerfect(5) : 0;
                    const cellTextAlign = item.length === 1 ? "left" : "center";
                    const cellTextFontSize = item.length === 1 ? appTheme.pixelPerfectForFont(12) : appTheme.pixelPerfectForFont(6);
                    const cellIconSize = item.length === 1 ? appTheme.pixelPerfect(24) : appTheme.pixelPerfect(24);
                    let subtitleView = <View />;
                    let isUserSubscriber = false;
                    const now = new Date().getTime();
                    if (user.sub !== undefined) {
                        let startDate = user.sub.start_date !== undefined ? user.sub.start_date : user.sub.date !== undefined ? user.sub.date : 0;
                        if ((startDate < now) && (user.sub.expires_date > now)) {
                            isUserSubscriber = true;
                        }
                    }
                    let iconSvgView = <SVGView Component={aCell.icon} size={cellIconSize} color={appTheme.white} />;
                    let showCell: boolean = true;
                    if (aCell.id === "editionMode") {
                        cellHeight = appTheme.pixelPerfect(80);
                        const editionModeDescription = editionModeValue === true ? "Mode admin activé" : "Mode admin désactivé";
                        const editionModeActivationExplanation = editionModeValue === true ? "Appuie ici pour le désactiver" : "Appuie ici pour l'activer";
                        iconColor = editionModeValue === false ? appTheme.alertOverlayColor : appTheme.grow;
                        subtitleView = <View>
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }]}>{editionModeDescription}</Text>
                            <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(8) }]}>{editionModeActivationExplanation}</Text>
                        </View>
                    } else if (aCell.id === "teacherProfileMode") {
                        if (user.profile === "teacher") {
                            showCell = false;
                        }
                        iconColor = appTheme.schoolColor;
                        iconSvgView = <Image source={images.icLiliAtSchoolCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    } else if (aCell.id === "animatorProfileMode") {
                        if (user.profile === "animator") {
                            showCell = false;
                        }
                        iconColor = appTheme.animatorColor;
                        iconSvgView = <Image source={images.icLiliAtExtracurricularCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    } else if (aCell.id === "familyProfileMode") {
                        if (user.profile === "family") {
                            showCell = false;
                        }
                        iconColor = appTheme.homeColor;
                        iconSvgView = <Image source={images.icLiliAtHomeCircle} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />;
                    }
                    let iconView = <View style={iconStyle}>
                        <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: iconColor, justifyContent: "center", alignItems: "center" }}>
                            {iconSvgView}
                        </View>
                    </View>;
                    if (showCell === true) {
                        cell.push(
                            <TouchableOpacity
                                accessibilityRole="button"
                                accessible={true}
                                accessibilityLabel={aCell.title}
                                onPress={() => openItem(aCell, isUserSubscriber)}
                                style={[Style.shadowed, { width: cellWidth, justifyContent: "center", alignItems: 'center', borderRadius: appTheme.pixelPerfect(20), marginHorizontal: appTheme.pixelPerfect(5) }]}>
                                <View style={{ flexDirection: cellFlexDirection, width: cellWidth, height: cellHeight, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), alignItems: 'center', justifyContent: horizontalAlign, paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: cellPaddingTop, paddingBottom: cellPaddingBottom }}>
                                    {iconView}
                                    <View style={{ padding: appTheme.pixelPerfect(10), justifyContent: 'center' }}>
                                        <Text style={{ textAlign: cellTextAlign, fontFamily: appTheme.secondaryFont, fontSize: cellTextFontSize, color: appTheme.darkBlue }}>
                                            {aCell.title.toLocaleUpperCase()}
                                        </Text>
                                        {subtitleView}
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )
                    }

                }
            }
        }
        return <View style={{ flexDirection: "row", width: listWidth, justifyContent: "center", alignItems: "center", marginBottom: appTheme.pixelPerfect(10) }}>
            {cell}
        </View>;
    }

    const onViewDidAppear = async () => {
        // On va récupérer la valeur du mode édition
        try {
            const adminDataString = await getLocalStorage({ key: Constants.keyAdminData });
            if (adminDataString === null) {
                setEditionModeValue(false);
            } else {
                const adminData = JSON.parse(adminDataString);
                if (adminData.editionMode !== undefined) {
                    setEditionModeValue(adminData.editionMode);
                } else {
                    setEditionModeValue(false);
                }
            }
        } catch (error) {
            setEditionModeValue(false);
        }
    }

    useEffect(() => {
        if (editionModeValue !== undefined) {
            setViewDidAppear(true);
        }
    }, [editionModeValue])

    const mainViewAlignItems = appTheme.getFlexDirectionForSplitScreen() === "row" ? "flex-start" : "center";

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground />
            <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                <View style={{ flex: 1, alignItems: mainViewAlignItems, width: appTheme.getFullAppWidth(), paddingTop: appTheme.pixelPerfect(30), flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                    <View style={{ width: headerWidth, justifyContent: "flex-start", alignItems: "center" }}>
                        <Image source={images.illSupercatStanding} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                        <Text
                            accessible={true}
                            accessibilityLabel={i18n.t("superAdmin.title")}
                            accessibilityRole="text"
                            style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14) }, { paddingTop: appTheme.pixelPerfect(10), color: appTheme.darkBlue }]}>{i18n.t("superAdmin.title").toLocaleUpperCase()}</Text>
                    </View>
                    <FlatList
                        data={menuData}
                        renderItem={aboutCell}
                        keyExtractor={keyExtractor}
                        style={{ flex: 1, width: listWidth, marginTop: appTheme.pixelPerfect(10) }} />
                </View>
            </SafeAreaView>
            <Alert ref={alertRef} />
        </Main>
    )
}

export default SuperAdmin;

const styles = StyleSheet.create({
    logoutButton: {
        justifyContent: "center",
        alignItems: "center"
    }
});