import React from "react";

import {
    Text,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import { UserType } from "./Landing";
import { Image } from "react-native";
import Images, { bundleImage } from "../../../../specific/utils/Images";
import i18n from "../../../utils/Localization/Localization";

interface AuthHeaderProps {
    type?: UserType
}

type IconDef = {
    source: bundleImage,
    width: number,
    height: number
}

type Argument = {
    id: string,
    icon: IconDef,
    title: string,
    subtitle: string
}

const AuthFooter = (props: AuthHeaderProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    // Déclaration des cellules pour l'argumentaire de profs
    const teacherArguments: Argument[] = [
        {
            id: "t_arg_01",
            icon: {
                source: images.icTeacherProfs,
                width: 50,
                height: 40
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_1.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_1.body")
        },
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_2.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_2.body")
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_3.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_3.body")
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.icTeacherSuperProgrammes,
                width: 90,
                height: 50
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_4.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_4.body")
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.illTeacherDocs,
                width: 61,
                height: 66
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_5.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_5.body")
        },
        {
            id: "t_arg_06",
            icon: {
                source: images.icMinistereWhite,
                width: 75,
                height: 60
            },
            title: i18n.t("authentication.signUp.form.teachers.args.arg_6.title"),
            subtitle: i18n.t("authentication.signUp.form.teachers.args.arg_6.body")
        }
    ]

    const periscolarArguments: Argument[] = [
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: i18n.t("authentication.signUp.form.animators.args.arg_1.title"),
            subtitle: i18n.t("authentication.signUp.form.animators.args.arg_1.body")
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: i18n.t("authentication.signUp.form.animators.args.arg_2.title"),
            subtitle: i18n.t("authentication.signUp.form.animators.args.arg_2.body")
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.icTeacherSuperProgrammes,
                width: 90,
                height: 50
            },
            title: i18n.t("authentication.signUp.form.animators.args.arg_3.title"),
            subtitle: i18n.t("authentication.signUp.form.animators.args.arg_3.body")
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.icPeriscolaire,
                width: 65,
                height: 60
            },
            title: i18n.t("authentication.signUp.form.animators.args.arg_4.title"),
            subtitle: i18n.t("authentication.signUp.form.animators.args.arg_4.body")
        },
        {
            id: "t_arg_06",
            icon: {
                source: images.icMinistereWhite,
                width: 75,
                height: 60
            },
            title: i18n.t("authentication.signUp.form.animators.args.arg_5.title"),
            subtitle: i18n.t("authentication.signUp.form.animators.args.arg_5.body")
        }
    ]

    const familyArguments: Argument[] = [
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: i18n.t("authentication.signUp.form.family.args.arg_1.title"),
            subtitle: i18n.t("authentication.signUp.form.family.args.arg_1.body")
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: i18n.t("authentication.signUp.form.family.args.arg_2.title"),
            subtitle: i18n.t("authentication.signUp.form.family.args.arg_2.body")
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.illSupercatStanding,
                width: 90,
                height: 50
            },
            title: i18n.t("authentication.signUp.form.family.args.arg_3.title"),
            subtitle: i18n.t("authentication.signUp.form.family.args.arg_3.body")
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.icOwlMaster,
                width: 50,
                height: 40
            },
            title: i18n.t("authentication.signUp.form.family.args.arg_4.title"),
            subtitle: i18n.t("authentication.signUp.form.family.args.arg_4.body")
        },
        {
            id: "t_arg_06",
            icon: {
                source: images.icMinistereWhite,
                width: 75,
                height: 60
            },
            title: i18n.t("authentication.signUp.form.family.args.arg_5.title"),
            subtitle: i18n.t("authentication.signUp.form.family.args.arg_5.body")
        }
    ]

    const getContent = () => {
        let argumentsToDisplay: Argument[] = [];
        let textColor = appTheme.darkBlue;
        if (props.type === "family") {
            textColor = appTheme.white;
            argumentsToDisplay = familyArguments;
        } else if (props.type === "animator") {
            argumentsToDisplay = periscolarArguments;
        } else if (props.type === "teacher") {
            argumentsToDisplay = teacherArguments;
        } else {
            return (<Image source={images.illSupercat} style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(125), marginTop: appTheme.pixelPerfect(10) }} resizeMode="contain" />);
        }
        let argumentView = [];
        for (const anArgumentKey in argumentsToDisplay) {
            if (Object.prototype.hasOwnProperty.call(argumentsToDisplay, anArgumentKey)) {
                const anArgument: Argument = argumentsToDisplay[anArgumentKey];
                argumentView.push(<View key={anArgument.id} style={{ justifyContent: "center", alignItems: "center", marginTop: appTheme.pixelPerfect(50), paddingHorizontal:appTheme.pixelPerfect(10) }}>
                    <Image source={anArgument.icon.source} style={{ marginBottom:appTheme.pixelPerfect(5), width: appTheme.pixelPerfect(anArgument.icon.width), height: appTheme.pixelPerfect(anArgument.icon.height) }} resizeMode="contain" />
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, textAlign: "center", textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(10), color: textColor }}>{anArgument.title}</Text>
                    <Text style={{ fontFamily: appTheme.secondaryFont, textAlign: "center", fontSize: appTheme.pixelPerfectForFont(8), color: textColor }}>{anArgument.subtitle}</Text>
                </View>)
            }
        }
        return argumentView;
    }

    return getContent();

}

export default AuthFooter;