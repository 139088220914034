import React, { useEffect, useState } from "react";

import {
    View,
    Text,
    Image,
    Modal
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";
import Activity from "../../models/Activity";
import GameTimer from "./GameTimer";
import GameCards from "./GameCards";

import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcVolumeOn } from "../../assets/svg/icons/solid/volume-up.svg";
import { default as IcVolumeOff } from "../../assets/svg/icons/solid/volume-off.svg";
import { default as IcPause } from "../../assets/svg/icons/solid/pause.svg";


import { default as IcPlay } from "../../assets/svg/icons/solid/play.svg";
import { default as IcRefresh } from "../../assets/svg/icons/solid/redo.svg";
import { default as IcQuit } from "../../assets/svg/icons/solid/times.svg";


import Button from "../../designSystem/Button";
import MainStyle from "../../styles/game/MainStyle";
import { onActivityCompleted } from "../../../specific/services/Specific_Database";

interface GameBoardProps {
    activity: Activity,
    color: string,
    playASound: (sound: string, source?: string) => void,
    onGameEnd: () => void,
    onGameCompleted: () => void,
    titleView: JSX.Element,
    resetGame: () => void,
    iosAirPlayButton: JSX.Element
}

const GameBoard = (props: GameBoardProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();
    const mainStyle: MainStyle = new MainStyle();

    const { titleView, color, playASound, activity, resetGame, onGameEnd, onGameCompleted, iosAirPlayButton } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [gameId, setGameId] = useState<number>(new Date().getTime());
    const [didResetGame, setDidResetGame] = useState<boolean>(false);
    const [pauseTimer, setPauseTimer] = useState<boolean>(true); // Par défaut le timer n'est pas lancé
    const [showPauseOption, setShowPauseOption] = useState<boolean>(false);
    const [showImageGameOption, setShowImageGameOption] = useState<boolean>(false);
    const [expertMode, setExpertMode] = useState<boolean|undefined>(undefined);
    const [modeChoiceUnlocked, setModeChoiceUnlocked] = useState<boolean>(false);

    const [gameOver, setGameOver] = useState<boolean>(false);
    const defaultMuteConfiguration = activity.mute !== undefined ? activity.mute : false;
    const [isMute, setIsMute] = useState<boolean>(defaultMuteConfiguration);
    const [startTime, setStartTime] = useState<number>(0);

    const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            playASound("time.mp3");
        }
        setViewDidAppear(true);
    }

    const setTimer = (on: boolean) => {
        if (on === true) {
            if (startTime === 0) {
                setStartTime(new Date().getTime());
            }
            setPauseTimer(!on);
        }
    }

    useEffect(() => {
        if (modeChoiceUnlocked === true) {
            setPauseTimer(true);
            setShowImageGameOption(true);
        }
    }, [modeChoiceUnlocked]);

    const onImageGameEasterEggUnblocked = () => {
        setModeChoiceUnlocked(true);   
    }

    useEffect(() => {
        if (expertMode !== undefined) {
            onResetRequest();
        }
    }, [expertMode]);

    const onChangeModeRequest = () => {
       setExpertMode(!expertMode);
    }

    const onGoBackToImageGame = () => {
        setPauseTimer(false);
        setShowImageGameOption(false);
    }

    const onPauseRequest = () => {
        setPauseTimer(true);
        setShowPauseOption(true);
    }

    const onContinueRequest = () => {
        setPauseTimer(false);
        setShowPauseOption(false);
    }

    const onResetRequest = () => {
        const gameTime = (new Date().getTime() - startTime) / 1000;
        if (gameTime > activity.duration / 2) {
            onGameCompleted();
            onActivityCompleted({
                activityKey: activity.key,
                sectionKey: activity.sectionKey,
                categoryKey: activity.categoryKeys[0],
                iteration: 1,
                detail: "",
                menu: ""
            });
        }
        resetGame();
    }

    useEffect(() => {
        if (gameOver === true) {
            setStartTime(0);
            setShowPauseOption(true);
            setPauseTimer(true);
        }
    }, [gameOver]);

    const onGameTimeEnd = () => {
        if (gameOver === false) {
            playASound("time.mp3");
            setGameOver(true);
        }
    }

    const getImageGameOption = () => {
        if (showImageGameOption === false) {
            return <View />;
        }

        const pausedViewTitle = "🥳 BRAVO !";

        return <View style={gameStyle.pauseModal}>
            <View style={[gameStyle.pauseModalOptions, mainStyle.shadowed]}>
                <View style={{ backgroundColor: appTheme.talk, width: appTheme.pixelPerfect(350), borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", paddingVertical: appTheme.pixelPerfect(10) }}>
                    <Text style={{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(14), textTransform: "uppercase" }}>{pausedViewTitle}</Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(350), alignItems: "center", marginVertical: appTheme.pixelPerfect(10) }}>
                    <Text style={{ paddingHorizontal:appTheme.pixelPerfect(10), color: appTheme.talk, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(12) }}>{"Tu as débloqué le mode EXPERT.\nTu peux maintenant choisir de jouer avec le mode NORMAL ou le mode EXPERT (10 cartes dont certaines inédites), en mettant le jeu en Pause !"}</Text>
                    <Button onPress={onGoBackToImageGame} icon={IcPlay} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title="Reprendre le jeu" />
                    <Button onPress={() => setExpertMode(!expertMode)} icon={IcRefresh} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title={((expertMode !== undefined) && (expertMode === true)) ? "Mode NORMAL" : "Mode expert"} />
                </View>
            </View>
        </View>;
    }

    const getPausedView = () => {
        if (showPauseOption === false) {
            return <View />;
        }

        const pausedViewTitle = gameOver === false ? "Jeu en pause" : "Jeu terminé";
        const goOnButton = gameOver === false ? <Button onPress={onContinueRequest} icon={IcPlay} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title="Reprendre le jeu" /> : <View />;
        const changeGameMode = modeChoiceUnlocked === true ?  <Button onPress={onChangeModeRequest} icon={IcRefresh} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title={((expertMode !== undefined) && (expertMode === true)) ? "Mode NORMAL" : "Mode expert"} /> : <View />;
        return <View style={gameStyle.pauseModal}>
            <View style={[gameStyle.pauseModalOptions, mainStyle.shadowed]}>
                <View style={{ backgroundColor: appTheme.talk, width: appTheme.pixelPerfect(350), borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", paddingVertical: appTheme.pixelPerfect(10) }}>
                    <Text style={{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(14), textTransform: "uppercase" }}>{pausedViewTitle}</Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(350), alignItems: "center", marginVertical: appTheme.pixelPerfect(10) }}>
                    {goOnButton}
                    <Button onPress={onResetRequest} icon={IcRefresh} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title="Recommencer le jeu" />
                    <Button onPress={onGameEnd} icon={IcQuit} textStyle={{ color: appTheme.talk }} style={gameStyle.pauseViewButtons} title="Quitter le jeu" />
                    {changeGameMode}
                </View>
            </View>
        </View>;
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: 1, height: 1 }} />
        }
        const volumeIcon = isMute === true ? IcVolumeOff : IcVolumeOn;
        const muteButton = <View style={gameStyle.muteButtonView}>
            <SVGIconButton backgroundColor="#FFFFFF" icon={volumeIcon} onPress={() => setIsMute(!isMute)} color={appTheme.talk} />
        </View>;
        //Gestion du temps
        const gameTime = activity.duration !== undefined ? parseInt(activity.duration) : 0;
        if (activity.action.type === "game_img") {
            return <View style={[gameStyle.gameBoardContainer, { flex: 1 }]}>
                <GameTimer onGameTimeEnd={onGameTimeEnd} gameId={gameId} pauseTimer={pauseTimer} isMute={isMute} playASound={playASound} gameTime={gameTime} color={color} />
                <GameCards expertMode={expertMode} onImageGameEasterEggUnblocked={onImageGameEasterEggUnblocked} isMute={isMute} playASound={playASound} activity={activity} color={color} setTimer={setTimer} pauseTimer={pauseTimer} />
                <View style={gameStyle.closeButtonView}>
                    <SVGIconButton icon={IcPause} onPress={onPauseRequest} color={appTheme.talk} />
                </View>
                {muteButton}
                {getPausedView()}
                {getImageGameOption()}
            </View>
        }
        return <View style={gameStyle.gameBoardContainer}>
            {titleView}
            <GameTimer onGameTimeEnd={onGameTimeEnd} gameId={gameId} pauseTimer={pauseTimer} isMute={isMute} playASound={playASound} gameTime={gameTime} color={color} />
            <GameCards isMute={isMute} playASound={playASound} activity={activity} color={color} setTimer={setTimer} pauseTimer={pauseTimer} />
            <View style={gameStyle.closeButtonView}>
                <SVGIconButton icon={IcPause} onPress={onPauseRequest} color={appTheme.talk} />
            </View>
            {muteButton}
            {iosAirPlayButton}
            {getPausedView()}
        </View>
    }

    return getContent();
}

export default GameBoard;