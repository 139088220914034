import React, { useEffect, useState, useRef } from "react";
import {
    Modal,
    PixelRatio,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
//import { PagerView } from "react-native-pager-view";
import shorthash from "shorthash";

import AppTheme from "../../utils/Theme";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import * as Constants from "../../utils/Constants";
import TutorialPage from "./TutorialPage";
import Style from "../../utils/Style";
import OtbImage from "../../utils/OtbImage";
import Button from "../../designSystem/Button";
import i18n, { getLanguage } from "../../utils/Localization/Localization";

import SVGView from "../../utils/SvgView";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import ProgressBar from "../../../specific/components/ProgressBar/ProgressBar";
import { onActivityCompleted } from "../../../specific/services/Specific_Database";

import { downloadUrl, getShortNameForUrl, getPathForOriginal, doesFileExist } from "../../../specific/components/Download/DownloadButton";
import { getLocalActivities, recordLocalActivities } from "../../../specific/utils/LocalStorage";
import ViewPager from "../../../specific/components/ViewPager/ViewPager";
import TutorialLargeImageView from "./TutorialLargeImageView";
import { Image } from "react-native-svg";

// TO DO : Remettre le bon stockage
//const stockageDir = ReactNativeBlobUtil.fs.dirs.CacheDir;
const stockageDir = "";

let downloadTasks = [];

const rotationForLargeImages = Platform.OS === "web" ? "0deg" : "90deg";
let didAppear = false;

let largeImagePosition: number = 0;

const TutorialPager = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = ((props !== undefined) && (props.location !== undefined) && (props.location.state !== undefined)) ? props.location.state : props;
    }

    const { activity, color, rules } = propsData;

    const appTheme: AppTheme = new AppTheme();

    const [completed, setCompleted] = useState(false);
    const [showLargeImage, setShowLargeImage] = useState(false);
    const [onScreenPage, setOnScreenPage] = useState(0);
    const [largeImageIndex, setLargeImageIndex] = useState(0);
    const [showLegend, setShowLegend] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [nbOfImageDownloaded, setNbOfImageDownloaded] = useState(0);
    const [activitiesSteps, setActivitiesSteps] = useState([]);

    const cardWidth: number = appTheme.getFullAppWidth();
    const cardMarginTop: number = appTheme.topInsets + appTheme.pixelPerfect(10);
    const cardheight: number = appTheme.getFullScreenHeight() - cardMarginTop;

    const tutorialStepsRef = useRef<ScrollView>(null);

    useEffect(() => {
        if (propsData.didAppear === true) {
            didAppear = true;
            largeImagePosition = 0;
            checkImageToDisplay();
        }
    }, []);

    useEffect(() => {
        if ((propsData.didAppear === true) && (didAppear === false)) {
            didAppear = true;
            checkImageToDisplay();
        }
    }, [propsData]);

    const checkImageToDisplay = () => {
        if ((activity.action.steps !== undefined) && (activity.action.steps.length > 0)) {
            if (Platform.OS === "web") {
                setActivitiesSteps(activity.action.steps);
                setImageLoaded(true);
                setImageLoading(false);
            } else {
                downloadImagesBeforeLaunch(activity.action.steps);
            }
        }
    }

    const downloadImagesBeforeLaunch = (steps: any[]) => {
        if (steps.length > 0) {
            if (Platform.OS === "web") {
                // On ne télécharge pas les images
                setImageLoaded(true);
                setImageLoading(false);
            } else {
                downloadElementsForTuto();
            }
        } else {
            setImageLoaded(true);
        }
    }

    // TUTO
    const downloadElementsForTuto = () => {
        let urlDownloaded: any[] = [];
        let urlsToDownload = activity.action.steps;
        downloadUnitElementForTuto({ urlDownloaded, urlsToDownload, index: 0 });
    }

    const downloadUnitElementForTuto = async (data: { urlDownloaded: any[], urlsToDownload: any[], index: number }) => {
        const { urlDownloaded, urlsToDownload, index } = data;
        const urlData = urlsToDownload[index];
        if (urlData !== undefined) {
            const scaleKey = Constants.getScaleKey();
            const urlToDownload = urlData.media.imgs[scaleKey] !== undefined ? urlData.media.imgs[scaleKey] : urlData.media.url;
            const shortname = getShortNameForUrl(urlToDownload);
            const destination = getPathForOriginal(shortname, "png", activity.key);
            try {
                const fileExist: boolean = await doesFileExist(destination);
                if (fileExist === false) {
                    await downloadUrl({
                        onDownloadStart: () => { },
                        shortname,
                        urlToDownload,
                        destination
                    });
                }
                urlDownloaded.push({
                    body: urlData.body,
                    media: {
                        type: "img",
                        url: destination
                    }
                });
                setNbOfImageDownloaded(urlDownloaded.length);
                if (urlDownloaded.length === urlsToDownload.length) {
                    treatTutoDownloaded({ urlDownloaded });
                } else {
                    downloadUnitElementForTuto({ urlDownloaded, urlsToDownload, index: index + 1 });
                }
            } catch (error) {
                setImageLoaded(true);
                setImageLoading(false);
            }
        } else {
            treatTutoDownloaded({ urlDownloaded });
        }
    }

    const treatTutoDownloaded = (data: { urlDownloaded: any[] }) => {
        const { urlDownloaded } = data;
        toggleOfflineData({
            action: {
                steps: urlDownloaded,
                title: activity.action.title,
                type: activity.action.type
            }
        });
    }

    const toggleOfflineData = async (offline: any | undefined) => {
        const localActivitiesFromStorage = await getLocalActivities();
        let localActivitiesData = localActivitiesFromStorage.data;
        let anActivity = localActivitiesData[activity.key];
        anActivity.offline = offline;
        localActivitiesData[activity.key] = anActivity;
        await recordLocalActivities({ data: localActivitiesData, ts: localActivitiesFromStorage.ts });
        setActivitiesSteps(anActivity.offline.action.steps);
        setImageLoaded(true);
        setImageLoading(false);
    }

    const cancelDownloads = () => {
        closeTheView();
    }

    const showGalleryMode = (index) => {
        setLargeImageIndex(index - 1);
        setShowLargeImage(true);
    }

    const hideGalleryMode = () => {
        setShowLegend(true);
        setShowLargeImage(false);
        changePage(largeImagePosition + 1);
    }

    const closeTheView = () => {
        if (completed === true) {
            if (props.onTutorialCompleted !== undefined) {
                props.onTutorialCompleted();
            }
            onActivityCompleted({
                activityKey: activity.key,
                sectionKey: activity.sectionKey,
                categoryKey: activity.categoryKeys[0],
                iteration: 1,
                detail: "",
                menu: ""
            });
        }
        if (propsData.closeTheTutorial) {
            propsData.closeTheTutorial();
        }
    }

    const changePage = (page: number) => {
        const x = page * cardWidth;
        tutorialStepsRef.current?.scrollTo({ x: x, y: 0, animated: true });
        setOnScreenPage(page);
    }

    const onLargeImageChange = (position: number) => {
        largeImagePosition = position;
    }

    const onScroll = (event) => {
        if (event.nativeEvent.contentOffset.x > (activity.action.steps.length - 2) * cardWidth) {
            if (completed === false) {
                setCompleted(true);
            }
        }
    }
    const localImage = activity.app_image;
    const remoteImage = activity.img_url;
    const imageToDisplay = (localImage.length > 0) ? localImage : remoteImage;
    const origin = (localImage.length > 0) ? Constants.ImageOrigin.BUNDLE : Constants.ImageOrigin.REMOTE;
    if (imageLoaded === false) {

        const mediaDowloaderDescription = imageLoading === false ? i18n.t('activities.tuto.mediaChecking') : i18n.t('activities.tuto.mediaDownloading');
        const loadingProgress = nbOfImageDownloaded / parseInt(activity.action.steps.length);
        return (<View style={{ backgroundColor: appTheme.white, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), justifyContent: 'center', alignItems: 'center' }}>
            <OtbImage
                resizeMode={"cover"}
                origin={origin}
                backup={remoteImage}
                source={{ uri: imageToDisplay }}
                style={{ position: "absolute", top: 0, left: 0, opacity: 0.3, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} />
            <View style={[Style.shadowed, { marginBottom: appTheme.pixelPerfect(40) }]}>
                <OtbImage
                    resizeMode={"cover"}
                    origin={origin}
                    backup={remoteImage}
                    source={{ uri: imageToDisplay }}
                    style={{ width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(150), borderRadius: appTheme.pixelPerfect(20), borderWidth: appTheme.pixelPerfect(5), borderColor: appTheme.white }} />
            </View>
            <View style={[Style.shadowed, { width: appTheme.getFullAppWidth(), borderTopRightRadius: appTheme.pixelPerfect(20), borderTopLeftRadius: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(20), position: "absolute", bottom: 0, paddingBottom: appTheme.pixelPerfect(30) + appTheme.bottomInsets, alignItems: "center", backgroundColor: appTheme.white }]}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(12), marginBottom: appTheme.pixelPerfect(10), color: appTheme.darkBlue }}>{mediaDowloaderDescription}</Text>
                <ProgressBar
                    borderWidth={2}
                    color={color}
                    indeterminate={nbOfImageDownloaded === 0}
                    progress={loadingProgress}
                    width={200} />
                <Button
                    title={i18n.t('activities.tuto.cancel')}
                    onPress={cancelDownloads}
                    style={{ backgroundColor: color, marginTop: appTheme.pixelPerfect(20), width: appTheme.pixelPerfect(240) }} />
            </View>

        </View>)
    }
    let tutorialSteps = [];
    tutorialSteps.push(<View key={'card_0'}><TutorialPage changePage={changePage} color={color} isRules={true} rules={rules} width={cardWidth} step={[]} /></View>);
    let scrollViewNumberOfPages = 1;
    let tutorialStepNumber = 0;
    let largeImages = [];
    const largeImageWidth = Platform.OS === 'web' ? appTheme.getFullScreenWidth() : appTheme.getFullScreenHeight();
    const largeImageHeight = Platform.OS === 'web' ? appTheme.getFullScreenHeight() : appTheme.getFullScreenWidth();
    if ((activitiesSteps !== undefined) && (activitiesSteps.length > 0)) {
        for (const anActivityStepIndex in activitiesSteps) {
            if (Object.prototype.hasOwnProperty.call(activitiesSteps, anActivityStepIndex)) {
                const step = activitiesSteps[anActivityStepIndex];
                tutorialStepNumber++;
                const largeImageKey = 'large_' + tutorialStepNumber;
                const cardKey = 'card_' + tutorialStepNumber;
                tutorialSteps.push(<View key={cardKey}>
                    <TutorialPage
                        key={cardKey}
                        showGalleryMode={showGalleryMode}
                        sectionId={activity.sectionId}
                        tags={activity.tags}
                        totalNbumberOfStep={activity.action.steps.length}
                        isFirstStep={scrollViewNumberOfPages === 0} stepNumber={tutorialStepNumber}
                        isLastStep={activity.action.steps.length === tutorialStepNumber}
                        changePage={changePage} color={color} isRules={false} rules={[]} width={cardWidth} step={step} /></View>);
                largeImages.push({
                    key: largeImageKey, view: <TutorialLargeImageView
                        height={largeImageHeight}
                        width={largeImageWidth}
                        color={color}
                        legend={step.body[getLanguage()]}
                        largeImageKey={largeImageKey}
                        uri={step.media.url} />
                });
                scrollViewNumberOfPages++;
            }
        }
    }
    const scrollViewWidth = scrollViewNumberOfPages * cardWidth;
    const fullScreenViewPagerStyle = Platform.OS === "web" ? { flex: 1 } : [styles.zoomed];
    const fullScreenViewPager = <View style={fullScreenViewPagerStyle}>
        <ViewPager
            onPageSelected={(position: number) => onLargeImageChange(position)}
            showNavigationButton={true}
            mainColor={color}
            isHorizontal={true}
            views={largeImages}
            pageToShow={Platform.OS === "web" ? 0 : onScreenPage > 0 ? onScreenPage - 1 : 0}
            style={{ height: largeImageHeight, width: largeImageWidth }} />
    </View>
    const fullScreenAbsolutePositionStyle = Platform.OS === "web" ? { top: appTheme.pixelPerfect(20) } : { bottom: appTheme.pixelPerfect(40) };
    const fullScreenCloseButton = <TouchableOpacity onPress={hideGalleryMode} style={[Style.shadowed, fullScreenAbsolutePositionStyle, { position: 'absolute', right: appTheme.pixelPerfect(10), justifyContent: 'center', alignItems: 'center', width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }]}>
        <SVGView Component={IcClose} height={appTheme.pixelPerfect(24)} width={appTheme.pixelPerfect(24)} color={color} />
    </TouchableOpacity>;
    return (
        <View style={[styles.container, { width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }]}>
            <View style={[Style.shadowed, {
                marginTop: cardMarginTop,
                borderRadius: appTheme.pixelPerfect(20)
            }, { backgroundColor: appTheme.white, width: cardWidth, minHeight: cardheight }]}>
                <View style={[{
                    borderTopLeftRadius: appTheme.pixelPerfect(20),
                    borderTopRightRadius: appTheme.pixelPerfect(20)
                }, { flexDirection: "row", width: cardWidth, backgroundColor: color, justifyContent: 'center', alignItems: 'center', height: appTheme.pixelPerfect(60) }]}>
                    <View style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60), justifyContent: "center", alignItems: "center" }}>
                        <OtbImage
                            resizeMode={"cover"}
                            origin={origin}
                            backup={remoteImage}
                            source={{ uri: imageToDisplay }}
                            style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }} />
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.white }}>{activity.title[getLanguage()].toLocaleUpperCase()}</Text>
                    </View>
                    <View style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60), justifyContent: "center", alignItems: "center" }}>
                        <TouchableOpacity onPress={closeTheView} style={{ justifyContent: 'center', alignItems: 'center', width: appTheme.pixelPerfect(36), height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }}>
                            <SVGView Component={IcClose} height={appTheme.pixelPerfect(22)} width={appTheme.pixelPerfect(22)} color={color} />
                        </TouchableOpacity>
                    </View>
                </View>
                <ScrollView
                    style={{ width: cardWidth }}
                    onScroll={onScroll}
                    horizontal={true}
                    scrollEnabled={Platform.OS !== "web"}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled={true}
                    ref={tutorialStepsRef}>
                    <View style={{ width: scrollViewWidth, flexDirection: 'row' }}>
                        {tutorialSteps}
                    </View>
                </ScrollView>
            </View>
            <Modal
                animationType="slide"
                transparent={false}
                visible={showLargeImage} >
                {fullScreenViewPager}
                {fullScreenCloseButton}
            </Modal>
        </View>
    )
}

export default TutorialPager;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#00000089',
        alignItems: 'center',
        justifyContent: 'center'
    },
    zoomed: {
        transform: [
            {
                rotate: rotationForLargeImages
            }
        ]
    }
});
